<template>
    <div
        class="main-page"
        style="margin-top: 0"
    >

        <template v-if="currentComponent !== 'CategoryManagement'">
            <tabbed-page
                :tabList="tabList"
                @changeTabs="changeTabs"
            >
            </tabbed-page>
        </template>
        <div class="page-box">
            <component
                :is="currentComponent"
                @switchClick="switchClick"
                @checkingInIdChange="checkingInIdChange"
                @routerReturnChange="routerReturnChange"
                :checkingInId="checkingInId"
                :routerReturn="routerReturn"
                :meetingId="meetingId"
                @setPageInitTabs="setPageInitTabs"
            >
            </component>
        </div>
    </div>
</template>
<script>
import TabbedPage from "./Sub/TabbedPage/index.vue";
import {tabPageHasAuth} from "@/libs/tabbedPageAuth.js";
export default {
    name: "SServiceSchReportScrollWrapper",
    components: {
        CampusRepairProcessing: () =>
            import(
                /* webpackChunkName:'CampusRepairProcessing' */ "./SServiceSchReportScrollWrapper/CampusRepairProcessing.vue"
            ),
        MyMaintenance: () =>
            import(
                /* webpackChunkName:'MyMaintenance' */ "./SServiceSchReportScrollWrapper/MyMaintenance.vue"
            ),
        MyApplicationRepair: () =>
            import(
                /* webpackChunkName:'MyApplicationRepair' */ "./SServiceSchReportScrollWrapper/MyApplicationRepair.vue"
            ),
        CategoryManagement: () =>
            import(
                /* webpackChunkName:'CategoryManagement' */ "./SServiceSchReportScrollWrapper/CategoryManagement.vue"
            ),
        TabbedPage,
    },
    data() {
        return {
            meetingId: undefined,
            currentComponent: "",
            checkingInId: undefined,
            routerReturn: undefined,
            comps: ["CampusRepairProcessing", "MyApplicationRepair","CategoryManagement"],
            tabList: [
                {
                    id: "1",
                    title: "报修处理",
                    vHasPermi: ["repair:repair"],
                    com: "CampusRepairProcessing",
                },
                {
                    id: "2",
                    title: "我的报修",
                    vHasPermi: ["myRepair:repair"],
                    com: "MyApplicationRepair",
                },
                {
                    id: "3",
                    title: "待我维修",
                    vHasPermi: ["repair:repairForMe"],
                    com: "MyMaintenance",
                },
            ],
            idx: 0,
        };
    },
    created() {
        this.init();
        const permissions = this.$store.state.permissions;

        if(this.$route.query.isWXTZ){
            this.changeTabs({id:"3"})
        }else{
            if (permissions.includes("repair:repair")) {
                this.currentComponent = "CampusRepairProcessing";
            } else if (permissions.includes("myRepair:repair")) {
                this.currentComponent = "MyApplicationRepair";
            }
        }
        
        this.$eventDispatch("setGlobalPageType", "list");
    },
    methods: {
        init() {
            this.tabList = tabPageHasAuth(this.tabList);
            this.comps = this.tabList.map((item) => item.com);
        },
        /**
         * @Description: 切换tab
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-08-16 10:01:06
         */
        changeTabs(data) {
            this.tabList.forEach((item, index) => {
                if (item.id === data.id) {
                    this.$set(item, "sel", true);
                    this.currentComponent = item.com;
                } else {
                    this.$set(item, "sel", false);
                }
            });
        },
        switchClick(page) {
            this.currentComponent = page;
        },
        checkingInIdChange(id) {
            this.checkingInId = id;
        },
        routerReturnChange(path) {
            this.routerReturn = path;
        },
        switchClick(page) {
            this.currentComponent = page;
        },
        meetingIdChange(id) {
            this.meetingId = id;
        },
        setPageInitTabs(){
            this.$eventDispatch('setGlobalPageType', 'list')
            this.idx = 0;
            this.currentComponent = 'CampusRepairProcessing';
        }
    },
};
</script>

<style lang="scss" scoped>
.switch-list {
    height: 54px;
    display: flex;
    padding: 0 30px;
    line-height: 54px;
    align-items: center;
    margin-top: 1px;
    background-color: #ffffff;
    border-radius: 4px 0 0 4px;
    .switch-item {
        color: #737373;
        cursor: pointer;
        font-size: 14px;
        margin-right: 48px;
    }

    .current-item {
        color: #2b2f33;
        font-weight: bold;
        line-height: 52px;
        border-bottom: solid 3px #177ee6;
    }
}

.page-box {
    // padding-right: 10px;
}
</style>
